import { useNavigate } from '@remix-run/react'
import { format, subDays } from 'date-fns'
import {
  LucideBuilding2,
  LucideChevronLeft,
  LucideChevronRight,
  LucideExternalLink,
  LucideMap,
  LucideMapPin,
  LucideMapPinned,
  LucideMinus,
  LucidePlusCircle,
  LucideSearch,
  LucideStar,
  LucideUserCircle,
} from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAdminCompaniesQuery, useAdminUsersQuery } from '~/api'
import {
  aMapFilters,
  aMarkLocation,
  aOrganization,
  aUser,
  useAtom,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import { Button, Command, Input } from '~/components/ui'
import {
  useDebounce,
  useFuseSearch,
  useGeocodeSearch,
  useIsEmbed,
} from '~/hooks'
import { UserRole } from '~/models'
import { cn } from '~/utils'
import GlobalSavedSearches from './SavedSearches'
import GlobalSearchListings from './SearchListings'

const getCreatedFilterUrl = (days: number) => {
  const date = subDays(new Date(), days)
  return `/?types=lease_sale&statuses=active&created=${format(
    date,
    'yyyy-MM-dd'
  )}`
}

enum Page {
  searchListings = 'searchListings',
  locations = 'locations',
  quickSearch = 'quickSearch',
  quickActions = 'quickActions',
  quickLinks = 'quickLinks',
  savedSearches = 'savedSearches',
  companies = 'companies',
  brokers = 'brokers',
}

const PAGES = [
  {
    name: 'Search Listings',
    pageName: Page.searchListings,
    icon: LucideMapPin,
  },
  {
    name: 'Quick Search',
    pageName: Page.quickSearch,
    icon: LucideMap,
  },
  {
    name: 'Quick Actions',
    pageName: Page.quickActions,
    icon: LucidePlusCircle,
    hideEmbed: true,
    loggedIn: true,
  },
  {
    name: 'Quick Links',
    pageName: Page.quickLinks,
    icon: LucideExternalLink,
    loggedIn: true,
  },
  {
    name: 'Search Address',
    pageName: Page.locations,
    icon: LucideMapPinned,
  },
  {
    name: 'Saved Searches',
    pageName: Page.savedSearches,
    icon: LucideStar,
    loggedIn: true,
    hideEmbed: true,
  },
  {
    name: 'Companies',
    pageName: Page.companies,
    icon: LucideBuilding2,
  },
  {
    name: 'Brokers',
    pageName: Page.brokers,
    icon: LucideUserCircle,
  },
]

const QUICK_SEARCH = [
  {
    name: 'For Lease',
    to: '/?types=lease&statuses=active',
  },
  {
    name: 'For Sale',
    to: '/?types=sale&statuses=active',
  },
  {
    name: 'For Sale/Lease',
    to: '/?types=lease_sale&statuses=active',
  },
  {
    name: 'Latest Listings (1 day)',
    to: getCreatedFilterUrl(1),
  },
  {
    name: 'Latest Listings (7 days)',
    to: getCreatedFilterUrl(7),
  },
  {
    name: 'Latest Listings (30 days)',
    to: getCreatedFilterUrl(30),
  },
]

const QUICK_ACTIONS = [
  {
    name: 'View My Listings',
    to: '/admin/listings/me',
  },
  {
    name: 'Create Listing',
    to: '/admin/listings/create',
  },
  // {
  //   name: 'Create Report',
  //   to: '/admin/listings/create',
  // },
  {
    name: 'Create Email',
    to: '/admin/emails/create',
  },
  {
    name: 'View Map',
    to: '/',
  },
]

type GlobalSearchProps = {
  className?: string
  onPlaceSelect?: (data: { lng: number; lat: number; zoom: number }) => void
}

export default function GlobalSearch({
  className,
  onPlaceSelect,
}: GlobalSearchProps) {
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)
  const navigate = useNavigate()
  const isEmbed = useIsEmbed()
  const [mapFilters, setMapFilters] = useAtom(aMapFilters)
  const [platformDevice, setPlatformDevice] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 200)
  const [pages, setPages] = useState<string[]>(['searchListings'])
  const page = pages[pages.length - 1]
  const setMarkLocation = useSetAtom(aMarkLocation)
  const searchListRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const navigateOut = (to: string) => {
    if (isEmbed) {
      window.open(to, '_blank')
    } else {
      navigate(to)
    }
  }

  const updatePages = (newPages: string[]) => {
    setSearch('')
    setPages(newPages)
    inputRef.current?.focus()
  }

  const {
    isLoading: isLoadingLocations,
    setSearch: setLocationSearch,
    predictions,
    getPlaceData,
  } = useGeocodeSearch()

  const { companies: companyData, isLoading: isLoadingCompanies } =
    useAdminCompaniesQuery(
      ['active-companies']
      // , { status: 'active' }
    )

  const filteredCompanies = useFuseSearch({
    query: debouncedSearch,
    list: companyData,
    keys: ['name', 'meta.email', 'meta.phone'],
  })

  const { users: brokerData, isLoading: isLoadingBrokers } = useAdminUsersQuery(
    ['active-brokers'],
    {
      status: 'active',
      role: {
        $in: [UserRole.broker, UserRole.managingBroker],
      },
    }
  )

  const filteredBrokers = useFuseSearch({
    query: debouncedSearch,
    list: brokerData,
    keys: [
      {
        name: 'name',
        getFn: (user) => `${user.firstName} ${user.lastName}`,
      },
      'email',
      'phone',
      'company.name',
    ],
  })

  useEffect(() => {
    if (window.navigator.userAgent.includes('Mac')) {
      setPlatformDevice('macOS')
    }
  }, [])

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setIsOpen((open) => !open)
      }
    }
    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        updatePages(
          location.pathname.includes('/admin') ? [] : ['searchListings']
        )
      }, 300)
    } else {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 50)
    }
  }, [isOpen])

  useEffect(() => {
    setLocationSearch(search)
    // setPages([])
  }, [search])

  const placeholder = useMemo(() => {
    const pagePlaceholders: { [key: string]: string } = {
      [Page.searchListings]: 'Search listings...',
      [Page.quickSearch]: 'Filter quick search...',
      [Page.quickActions]: 'Filter quick actions...',
      [Page.quickLinks]: 'Filter quick links...',
      [Page.locations]: 'Search address...',
      [Page.savedSearches]: 'Filter saved views...',
      [Page.companies]: 'Filter companies...',
      [Page.brokers]: 'Filter brokers...',
    }
    return page ? pagePlaceholders[page] : 'Select a search group to begin'
  }, [page])

  const renderQuickSearch = () => {
    // if ((search.length > 0 && !page) || page === Page.quickSearch) {
    if (page === Page.quickSearch) {
      const quickSearch = QUICK_SEARCH.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
      if (quickSearch.length === 0) {
        return null
      }
      return (
        <Command.Group heading="Quick Search">
          {quickSearch.map(({ name, to }) => (
            <Command.Item
              key={name}
              onSelect={() => {
                if (!window.location.pathname.includes('/admin')) {
                  const params = new URLSearchParams(to.replace('/?', ''))
                  const filters: { [key: string]: any } = {
                    lat: mapFilters.lat,
                    lng: mapFilters.lng,
                    z: mapFilters.z,
                  }
                  for (const [key, value] of params.entries()) {
                    filters[key] = value
                  }
                  setMapFilters(filters)
                } else {
                  navigateOut(to)
                }
                setIsOpen(false)
              }}>
              {name}
            </Command.Item>
          ))}
        </Command.Group>
      )
    }
    return null
  }

  const renderQuickActions = () => {
    if (isEmbed) {
      return null
    }
    // if ((search.length > 0 && !page) || page === Page.quickActions) {
    if (page === Page.quickActions) {
      const quickActions = QUICK_ACTIONS.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
      if (quickActions.length === 0) {
        return null
      }
      return (
        <Command.Group heading="Quick Actions">
          {quickActions.map(({ name, to }) => (
            <Command.Item
              key={name}
              onSelect={() => {
                navigateOut(to)
                setIsOpen(false)
              }}>
              {name}
            </Command.Item>
          ))}
        </Command.Group>
      )
    }
    return null
  }

  const renderQuickLinks = () => {
    // if ((search.length > 0 && !page) || page === Page.quickLinks) {
    if (page === Page.quickLinks) {
      const quickLinks = (organization?.links || []).filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
      if (quickLinks.length === 0) {
        return null
      }
      return (
        <Command.Group heading="Quick Links">
          {quickLinks.map(({ name, url }) => (
            <Command.Item
              key={name}
              onSelect={() => {
                window.open(url, '_newtab')
                setIsOpen(false)
              }}>
              {name}
            </Command.Item>
          ))}
        </Command.Group>
      )
    }
    return null
  }

  const renderLocations = () => {
    // if (
    //   search.length > 0 &&
    //   predictions.length > 0 &&
    //   (!page || page === Page.locations)
    // )
    if (page === Page.locations) {
      if (predictions.length === 0) {
        return null
      }
      return (
        <Command.Group heading="Address">
          {predictions
            .slice(0, !page ? 3 : predictions.length)
            .map((prediction) => {
              return (
                <Command.Item
                  key={prediction.place_id}
                  onSelect={async () => {
                    const placeData = await getPlaceData(prediction)
                    setIsOpen(false)
                    if (!placeData) {
                      // error
                    } else if (onPlaceSelect) {
                      setMarkLocation(true)
                      onPlaceSelect(placeData)
                    } else {
                      setMarkLocation(true)
                      const { lng, lat, zoom } = placeData
                      navigateOut(
                        `/?lng=${lng}&lat=${lat}&z=${zoom > 16 ? 16 : zoom}`
                      )
                    }
                  }}>
                  {`${prediction.structured_formatting.main_text} ${prediction.structured_formatting.secondary_text}`}
                </Command.Item>
              )
            })}
        </Command.Group>
      )
    }
    return null
  }

  const renderCompanies = () => {
    // if ((search.length > 0 && !page) || page === Page.companies) {
    if (page === Page.companies) {
      if (filteredCompanies.length === 0) {
        return null
      }
      return (
        <Command.Group heading="Companies">
          {filteredCompanies
            .slice(0, !page ? 3 : filteredCompanies.length)
            .map(({ name, slug }, index) => (
              <Command.Item
                key={`company-${index}`}
                onSelect={() => {
                  navigateOut(`/companies/${slug}`)
                  setIsOpen(false)
                }}>
                {name}
              </Command.Item>
            ))}
        </Command.Group>
      )
    }
    return null
  }

  const renderBrokers = () => {
    // if ((search.length > 0 && !page) || page === Page.brokers) {
    if (page === Page.brokers) {
      if (filteredBrokers.length === 0) {
        return null
      }
      return (
        <Command.Group heading="Brokers">
          {filteredBrokers
            .slice(0, !page ? 3 : filteredBrokers.length)
            .map(({ firstName, lastName, email, slug }, index) => (
              <Command.Item
                key={`broker-${index}`}
                onSelect={() => {
                  navigateOut(`/brokers/${slug}`)
                  setIsOpen(false)
                }}>
                <span>
                  {firstName} {lastName}
                </span>
                <LucideMinus className="mx-1 !h-2 !w-2 text-muted-foreground " />
                <span className="text-muted-foreground">{email}</span>
              </Command.Item>
            ))}
        </Command.Group>
      )
    }
    return null
  }

  return (
    <>
      <button
        id="global-search"
        className={cn('relative', className)}
        onClick={() => setIsOpen(true)}>
        <LucideSearch className="absolute left-2.5 top-1/2 h-4 w-4 -translate-y-1/2 transform text-muted-foreground" />
        <Input
          placeholder="Global Search..."
          className="pointer-events-none pl-8"
        />
        {platformDevice === null ? null : (
          <kbd className="pointer-events-none absolute right-2 top-1/2 inline-flex h-5 -translate-y-1/2 select-none items-center gap-1 rounded bg-muted px-1.5 font-mono text-[13px] font-medium text-muted-foreground opacity-100">
            {platformDevice === 'macOS' ? (
              <span className="text-base">⌘</span>
            ) : (
              <span className="text-xs">Ctrl</span>
            )}
            K
          </kbd>
        )}
      </button>

      <Command.Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Command
          shouldFilter={!page}
          loop={true}
          onKeyDown={(e) => {
            if (e.key === 'Escape' && !page) {
              setIsOpen(false)
            } else if (
              e.key === 'Escape' ||
              (e.key === 'Backspace' && !search)
            ) {
              e.preventDefault()
              updatePages(pages.slice(0, -1))
            }
          }}>
          <div className="flex w-full gap-2">
            <Command.Input
              ref={inputRef}
              containerClassName={cn('w-full', page && '!pl-1')}
              icon={
                page ? (
                  <Button
                    className="mr-1 inline-flex w-auto items-center pl-1 pr-2"
                    variant="ghost"
                    onClick={() => updatePages(pages.slice(0, -1))}>
                    <LucideChevronLeft className="h-6 w-6 text-muted-foreground" />
                    <span>All</span>
                  </Button>
                ) : (
                  <LucideSearch className="ml-1 mr-3 h-4 w-4 shrink-0 opacity-50" />
                )
              }
              placeholder={placeholder}
              value={search}
              onValueChange={(value) => {
                setSearch(value)
                if (searchListRef.current) {
                  searchListRef.current.scrollTop = 0
                }
              }}
            />
          </div>
          <Command.List ref={searchListRef}>
            {page !== Page.searchListings && (
              <Command.Empty className="p-4">
                {page === Page.locations ? (
                  <span>Start typing to search map by address</span>
                ) : (
                  <div>
                    <p>No search groups found</p>
                    <p className="italic">
                      Select a group before search for listings, saved searches,
                      companies, etc.
                    </p>
                  </div>
                )}
              </Command.Empty>
            )}
            {!page && (
              <Command.Group>
                {PAGES.filter(({ loggedIn, hideEmbed }) => {
                  if (loggedIn && !user) {
                    return false
                  } else if (hideEmbed && isEmbed) {
                    return false
                  }
                  return true
                }).map(({ name, pageName, icon: ItemIcon }) => (
                  <Command.Item
                    key={name}
                    onSelect={() => {
                      updatePages([...pages, pageName])
                    }}
                    className="justify-between">
                    <span className="inline-flex">
                      <ItemIcon className="mr-3 h-6 w-6 text-muted-foreground" />

                      {name}
                    </span>
                    <LucideChevronRight className="h-4 w-4 text-muted-foreground" />
                  </Command.Item>
                ))}
              </Command.Group>
            )}
            {page === Page.searchListings && (
              <GlobalSearchListings
                search={debouncedSearch}
                setIsOpen={setIsOpen}
              />
            )}

            {page === Page.savedSearches && (
              <GlobalSavedSearches
                search={debouncedSearch}
                setIsOpen={setIsOpen}
                isCurrentPage={page === Page.savedSearches}
                condition={
                  (search.length > 0 && !page) || page === Page.savedSearches
                }
              />
            )}
            {renderQuickSearch()}
            {renderQuickActions()}
            {renderQuickLinks()}
            {renderLocations()}
            {renderCompanies()}
            {renderBrokers()}
          </Command.List>
        </Command>
      </Command.Dialog>
    </>
  )
}
